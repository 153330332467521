import { render, staticRenderFns } from "./PersonalInfoComponent.vue?vue&type=template&id=de01a792&scoped=true&"
import script from "./PersonalInfoComponent.vue?vue&type=script&lang=js&"
export * from "./PersonalInfoComponent.vue?vue&type=script&lang=js&"
import style0 from "./PersonalInfoComponent.vue?vue&type=style&index=0&id=de01a792&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de01a792",
  null
  
)

export default component.exports