<template>
    <v-container fluid>
        <v-card elevation="0">
            <v-card-text class="pa-0 ma-0">
                <v-form lazy-validation ref="complete">
                    <v-row>
                        <v-col class="text-center" cols="12">
                            <v-avatar @click="select" class="mx-auto" color="primary" size="128">
                                <img
                                        :src="this.avatar || 'https://www.shareicon.net/data/128x128/2016/07/26/802001_man_512x512.png'"
                                >
                            </v-avatar>

                            <v-file-input @change="onFileChange" accept="Image/*" class="d-none" id="avatar"
                            />
                        </v-col>
                        <v-col class="text-center ma-0 pa-0" cols="12">
                            <v-btn @click="select" class="mx-auto" color="primary">{{ $t('generic.lang_select') }}</v-btn>
                        </v-col>

                        <!-- main info -->
                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          :label="this.$t('generic.lang_Abteilung')"
                                          @focus="showTouchKeyboard" dense
                                          outlined
                                          v-model="form.department"/>
                        </v-col>


                        <v-col cols="12" md="4" sm="6">
                            <BirthdayPicker :label="$t('customers.lang_cust_birth')" dense
                                            v-bind:current-date="form.birthday" v-model="form.birthday"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="this.roles"
                                      @focus="showTouchKeyboard" dense item-color="roleColor"
                                      item-text="roleName"
                                      item-value="id" :label="$t('settings.lang_role')" outlined v-model="form.role"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          :label="this.$t('settings.lang_hoursWeek')" @focus="showTouchKeyboard" dense
                                          outlined type="number"
                                          v-model="form.hoursPerWeek"/>
                        </v-col>


                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          :label="this.$t('settings.lang_SalaryHour')" @focus="showTouchKeyboard" dense
                                          outlined type="number"
                                          v-model="form.salaryPerHour"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          :label="this.$t('generic.lang_targetSalesIn') + ' '+$store.getters['settings/currencyData']('symbol')"

                                          @focus="showTouchKeyboard" dense outlined
                                          type="number"
                                          v-model="form.targetSales"/>
                        </v-col>


                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          :label="this.$t('customers.lang_cust_tel_priv')"
                                          @focus="showTouchKeyboard"
                                          class="pb-0" dense outlined
                                          type="tel"
                                          v-model="form.phonePrivate"/>
                            <v-switch :label="this.$t('settings.langHasWhatsApp')" class="pa-0 ma-0"
                                      v-model="form.hasWhatsapp"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          :label="$t('customers.lang_cust_tel_business')"
                                          @focus="showTouchKeyboard"
                                          dense outlined type="tel"
                                          v-model="form.phoneOffice"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          :label="$t('generic.lang_emailAdresse')"
                                          @focus="showTouchKeyboard"
                                          dense outlined type="tel"
                                          v-model="form.email"/>
                        </v-col>

                        <v-col class="ma-0 pa-0" cols="12">
                            <v-divider class="ma-0 pa-0"/>
                        </v-col>
                        <!-- end main info -->

                        <v-col cols="12" md="4" sm="6">
                            <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="this.maritalStatus"
                                      :label="this.$t('settings.lang_maritalStatus')" @focus="showTouchKeyboard" dense
                                      outlined
                                      v-model="form.maritalStatus"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          :label="this.$t('settings.lang_placeOfBirth')" @focus="showTouchKeyboard"
                                          dense outlined
                                          v-model="form.placeOfBirth"/>
                        </v-col>


                        <v-col cols="12" md="4" sm="6">
                            <country :label="this.$t('settings.lang_nationality')" dense outlined
                                     v-bind:code="form.nationality" v-model="form.nationality"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          :label="$t('generic.lang_street')"
                                          :lang="10"
                                          @focus="showTouchKeyboard" dense
                                          outlined v-model="form.street"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('customers.lang_number')"
                                          :rules="[v => v.length <= 10 || 'must be less than 10 characters']"
                                          @focus="showTouchKeyboard" dense outlined
                                          v-model="form.houseNumber"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('customers.lang_cust_zip')"
                                          :rules="[v => v.length <= 10 || 'must be less than 10 characters']"
                                          @focus="showTouchKeyboard" dense
                                          outlined
                                          v-model="form.zip"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL" :label="$t('settings.lang_city')"
                                          @focus="showTouchKeyboard" dense
                                          outlined
                                          v-model="form.city"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          :label="this.$t('settings.lang_insuranceNo')" @focus="showTouchKeyboard" dense
                                          outlined
                                          type="number"
                                          v-model="form.insuranceNumber"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          @focus="showTouchKeyboard" dense :label="$t('settings.lang_bank')" outlined
                                          v-model="form.BANK"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          @focus="showTouchKeyboard" dense label="BIC" outlined type="number"
                                          v-model="form.BIC"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          @focus="showTouchKeyboard" dense label="IBAN" outlined type="number"
                                          v-model="form.IBAN"/>
                        </v-col>


                        <v-col cols="12" md="4" sm="6">
                            <v-switch :label="$t('settings.lang_isThereDisability')"
                                      v-model="form.severelyDisabled" value="1"/>
                        </v-col>

                        <!--employement -->
                        <v-col class="pb-0 mb-0" cols="12">
                            <h4>{{$t('settings.lang_Employment')}}</h4>
                        </v-col>
                        <v-col class="pa-t pb-t mt-0 mb-0" cols="12">
                            <v-divider class="pa-t pb-t mt-0 mb-0"/>
                        </v-col>


                        <v-col cols="12" md="4" sm="6">
                            <v-menu
                                    :close-on-content-click="false"
                                    :return-value.sync="form.entryDate"
                                    min-width="290px"
                                    offset-y
                                    ref="menu2"
                                    transition="scale-transition"
                                    v-model="menu2"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  :label="$t('settings.lang_dateOfEntry')"
                                                  @focus="showTouchKeyboard"
                                                  dense
                                                  outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-model="form.entryDate"
                                                  v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker no-title scrollable v-model="form.entryDate">
                                    <v-spacer></v-spacer>
                                    <v-btn @click="menu2 = false" color="primary" text>{{ $t('generic.lang_cancel') }}</v-btn>
                                    <v-btn @click="$refs.menu2.save(form.entryDate)" color="primary" text>{{ $t('generic.lang_ok') }}</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="this.YesNO"
                                      :label="this.$t('settings.lang_istThisPrimaryJob')" @focus="showTouchKeyboard"
                                      dense
                                      item-text="name"
                                      item-value="id" outlined
                                      v-model="form.primaryJob"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="this.YesNO"
                                      :label="this.$t('settings.lang_furtherEmployments')" @focus="showTouchKeyboard"
                                      dense
                                      item-text="name"
                                      item-value="id" outlined
                                      v-model="form.moreOccupation"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="this.YesNO"
                                      @focus="showTouchKeyboard" dense item-text="name"
                                      item-value="id"
                                      :label="$t('settings.lang_isThisMarginalJob')" outlined
                                      v-model="form.marginalJob"/>
                        </v-col>

                        <v-col cols="12" md="4" sm="6">
                            <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="this.highestSchol"
                                      :label="this.$t('settings.lang_highestSchoolDegree')" @focus="showTouchKeyboard"
                                      dense
                                      item-text="name"
                                      item-value="id" outlined
                                      v-model="form.highestScholDegree"/>
                        </v-col>


                        <v-col cols="12" md="4" sm="6">
                            <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="this.highestVocationalEducation"
                                      :label="this.$t('settings.lang_highestVocationalEducation')"
                                      @focus="showTouchKeyboard"
                                      dense item-text="name"
                                      item-value="id" outlined
                                      v-model="form.highestVocationalEducation"/>
                        </v-col>
                        <!--employement -->

                        <!--Accept certificates electronically (Bea) -->
                        <v-col class="pb-0 mb-0" cols="12">
                            <h4>{{$t('settings.lang_beaElectronicTransmission')}}</h4>
                        </v-col>
                        <v-col class="pa-t pb-t mt-0 mb-0" cols="12">
                            <v-divider class="pa-t pb-t mt-0 mb-0"/>
                        </v-col>

                        <v-col cols="12">
                            <v-checkbox class="pa-2 ma-0"
                                        :label="this.$t('settings.lang_agreeBea')"
                                        v-model="form.agreeCertificatElectronic"
                                        value="1"/>
                        </v-col>

                        <v-col class="mt-0 pt-0" cols="12">
                            <h5 class="font-weight-bolder">{{$t('accounting.lang_taxes')}}</h5>
                            <v-row class="pa-0 ma-0">
                                <v-col cols="12" sm="6">
                                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  @focus="showTouchKeyboard" dense :label="this.$t('settings.lang_taxIdendNo')"
                                                  outlined type="number"
                                                  v-model="form.taxIdentificationNumber"/>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  @focus="showTouchKeyboard" dense :label="this.$t('settings.lang_taxBracket')" outlined
                                                  v-model="form.taxBracketFactor"/>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  @focus="showTouchKeyboard" dense :label="this.$t('settings.lang_taxChildAllowance')" outlined
                                                  v-model="form.childAllowances"/>
                                </v-col>

                                <v-col cols="12" sm="6">
                                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                                  @focus="showTouchKeyboard" dense :label="this.$t('settings.lang_Denomination')" outlined
                                                  v-model="form.denomination"/>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!--Accept certificates electronically (Bea) -->

                        <!-- social insurance -->
                        <v-col class="pb-0 mb-0" cols="12">
                            <h4>{{$t('settings.lang_Socialinsurance')}}</h4>
                        </v-col>
                        <v-col class="pa-t pb-t mt-0 mb-0" cols="12">
                            <v-divider class="pa-t pb-t mt-0 mb-0"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                          @focus="showTouchKeyboard" dense :label="this.$t('settings.lang_taxHealInsurance')" outlined
                                          v-model="form.healthInsurance"/>
                        </v-col>

                        <v-col cols="12" sm="6">
                            <v-select :data-layout="KEYBOARD.KEYSETS.NORMAL" :items="this.YesNO"
                                      @focus="showTouchKeyboard" dense item-text="name"
                                      item-value="id"
                                      :label="$t('settings.lang_parentalProperty')" outlined
                                      v-model="form.parental"/>
                        </v-col>

                        <!-- social insurance -->


                        <!-- Information on the working papers -->
                        <v-col class="pb-0 mb-0" cols="12">
                            <h4>{{ $t('settings.lang_informationOnTheWorkingPapers') }}</h4>
                        </v-col>
                        <v-col class="pa-t pb-t mt-0 mb-0" cols="12">
                            <v-divider class="pa-t pb-t mt-0 mb-0"/>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-switch class="ma-0 pa-0" :label="$t('settings.lang_employmentContract')" v-model="form.employmentContract"
                                      value="1"/>
                        </v-col>

                        <v-col c cols="12" md="6">
                            <v-switch class="ma-0 pa-0"
                                      :label="$t('settings.lang_certificateOfVatDeduction')"
                                      v-model="form.certificateVATDeduction"
                                      value="1"/>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-switch class="ma-0 pa-0" label="SV ID"
                                      v-model="form.SVID"
                                      value="1"/>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-switch class="ma-0 pa-0" :label="$t('settings.lang_certificateOfPrivateHealthInsurance')"
                                      v-model="form.hasHealthInsurance"
                                      value="1"/>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-switch class="ma-0 pa-0" :label="$t('settings.lang_proofOfParentalStatus')"
                                      v-model="form.proofParentalStatus"
                                      value="1"/>
                        </v-col>

                        <!-- Information on the working papers -->


                        <!-- Information on taxable short-term pre-employment periods in the current calendar year -->
                        <v-col class="pb-0 mb-0" cols="12">
                            <h4>{{ $t('settings.lang_informationOnTaxableShortTermPreEmploymentReriods') }}</h4>
                        </v-col>
                        <v-col class="pa-t pb-t mt-0 mb-0" cols="12">
                            <v-divider class="pa-t pb-t mt-0 mb-0"/>
                        </v-col>

                        <v-col class="text-right" cols="12">
                            <v-spacer/>
                            <v-btn @click="addExperice()" class="mx-auto" color="primary" icon x-large>
                                <v-icon>add</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-simple-table
                            >
                                <template v-slot:default>
                                    <thead>
                                    <tr>
                                        <th class="text-left">{{ $t('settings.lang_dateFrom') }}</th>
                                        <th class="text-left">{{ $t('settings.lang_dateTo') }}</th>
                                        <th class="text-left">{{ $t('settings.lang_typeOfEmployment') }}</th>
                                        <th class="text-left">{{ $t('settings.lang_numberOfDaysOfEmployment') }}</th>
                                        <th class="text-left">{{ $t('generic.lang_delete') }}</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr :key="i" v-for="(item , i) in form.taxableInformation">
                                        <td :key="j" class="pa-0" v-for="(key , j) in item">
                                            <v-row align="center" class="pa-0 ma-0" justify="center">
                                                <v-col class="mx-auto mb-0 pb-0">

                                                    <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"

                                                                  @focus="showTouchKeyboard" dense outlined
                                                                  v-model="form.taxableInformation[i][j]"/>
                                                </v-col>
                                            </v-row>
                                        </td>
                                        <td class="pa-0">
                                            <v-btn @click="deleteExperience(i)" class="mb-5" color="error" icon>
                                                <v-icon>delete</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                        <!-- Information on taxable short-term pre-employment periods in the current calendar year -->

                        <v-col>
                            <v-divider class="pb-0 mb-0"/>
                        </v-col>

                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-text>
                <div id="onScreenKeyboardDiv">
                    <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                        :defaultKeySet="touchKeyboard.keySet"
                                        :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                        :options="touchKeyboard.options" class="internalWidthExpanded"
                                        id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
                </div>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>


    import Country from "../../../../common/country";
    import mixin from "../../../../../mixins/KeyboardMixIns";
    import BirthdayPicker from "../../../../common/birthdaypicker";

    export default {
        name: "JobInformationComponent",
        components: {Country, BirthdayPicker},
        mixins: [mixin],
        props: {
            roles: Array,
            form: Object
        },
        data: () => {
            return {
                avatar: null,
                menu: null,
                menu2: null,
                user: null,
                loading: false,
                countries: [],
                maritalStatus: [
                    'married',
                    'single',
                    'unmarried'
                ],
                highestSchol: [
                    "ohne Schulabschluss",
                    "Haupt-/Volksschulabschluss",
                    "Mittlere Reife/gleichwertiger Abschluss",
                    "Abitur/Fachabitur"
                ],
                highestVocationalEducation: [
                    "ohne beruflichen Ausbildungsabschluss",
                    "Anerkannte Berufsausbildung",
                    "Meister/Techniker/gleichwertiger Fachschulabschluss",
                    "Bachelor",
                    "Diplom/Magister/ Master/Staatsexamen",
                    "Promotion"
                ]
            }
        },
        computed: {
            YesNO: function () {
                return [
                    {
                        id: "1",
                        name: this.$t('generic.lang_yes')
                    },
                    {
                        id: "0",
                        name: this.$t('generic.lang_no')
                    }
                ]
            }
        },
      mounted() {
            this.form.taxableInformation = this.form.taxableInformation || [];
            this.avatar = this.form.avatar === "" ? null : this.form.avatar;
            this.form.avatar = null;
        },
        methods: {
            validate() {
                return this.$refs.complete.validate();
            },
            getBase64(file) {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
            },
            onFileChange(e) {
                this.getBase64(e).then(
                    data => {
                        this.avatar = data;
                        this.$emit('onFileChange', data);
                    }
                );

            },
            select() {
                document.getElementById('avatar').click();
            },
            deleteExperience(i) {
                this.form.taxableInformation.splice(i, 1);
            },
            addExperice() {
                this.form.taxableInformation.push(
                    {
                        from: "",
                        to: "",
                        employmentType: "",
                        employmentDaysNumber: ""
                    }
                )
            },
        }
    }
</script>

<style scoped>

</style>